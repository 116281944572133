
// Fonts
@import url(https://fonts.googleapis.com/css?family=Raleway:300,400,600);

// Variables
@import "variables";

// Bootstrap
@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap";

@import "~bootstrap-slider/src/sass/bootstrap-slider.scss";

@import "~pretty-checkbox";


$fa-font-path: "../../../node_modules/font-awesome/fonts" !default;

@import 'node_modules/font-awesome/scss/font-awesome.scss';
@import "../../js/cla-vue-template/src/index.css";
// @import 'footer';

#umn-main {
      position: static;
  z-index: auto;
  top: auto;
  right: auto;
  bottom: auto;
  left: auto;
  visibility: visible;
  float: none;
  clear: none;
  overflow: visible;
  width: 100%;
  min-width: 0;
  max-width: none;
  height: auto;
  min-height: 0;
  max-height: none;
  margin: 0 auto;
  padding: 0;
  text-align: left;
  white-space: normal;
  text-decoration: none;
  text-indent: 0;
  letter-spacing: normal;
  text-transform: none;
  border: none;
  background: none;
  font: normal normal normal 14px/20px Helvetica, "Helvetica Neue", "Futura", Verdana, "Geneva", sans-serif;
  line-height: 20px;
}